import { defineStore } from 'pinia'
import { computed } from 'vue'
import { useStorage } from '@vueuse/core'
import useAPI from '@/composables/useApi'

const STORAGE_KEY = 'NAZ_TOKEN'

export const useAuthentificationStore = defineStore('useAuthentificationStore', () => {
  const token = useStorage(STORAGE_KEY, '')
  const API = useAPI()

  const isLogged = computed(() => {
    return token.value !== ''
  })

  const login = async (username, password) => {
    const response = await API.fetch('/login', {
      method: 'POST',
      data: {
        username,
        password
      },
      version: ''
    })
    if (response.data.token) {
      setToken(response.data.token)
    }
    return response
  }

  const setToken = (value) => {
    token.value = value
    localStorage.setItem(STORAGE_KEY, token)
  }

  const disconnect = () => {
    token.value = ''
    localStorage.removeItem(STORAGE_KEY)
  }

  return {
    token,
    isLogged,
    login,
    setToken,
    disconnect
  }
})
