import { defineStore } from 'pinia'
import { computed } from 'vue'
import { useAPI, useListWithCheck } from '@/composables'
import { StatusData } from '@/datas/customers/status'

export const useCustomersStore = defineStore('useCustomersStore', () => {
  const listWithCheck = useListWithCheck('NAZ_CUSTOMERS_LIST')

  const getCertifiedNumber = computed(() => {
    return listWithCheck.list.value.filter((item) => item.status === StatusData.STATUS_CERTIFIED).length
  })

  const getInProgressNumber = computed(() => {
    return listWithCheck.list.value.filter((item) => item.status < StatusData.STATUS_CERTIFIED).length
  })

  const getSuspendedNumber = computed(() => {
    return listWithCheck.list.value.filter((item) => item.status === StatusData.STATUS_DISABLED).length
  })

  const searchByName = (name) => {
    const regex = new RegExp('^' + name.toLowerCase(), 'g')
    return listWithCheck.list.value.reduce((acc, item) => {
      if (item.name.toLowerCase().match(regex)) {
        acc.push(item)
      }
      return acc
    }, [])
  }

  const setList = listWithCheck.setCheckList

  const setLogo = (url, id) => {
    listWithCheck.list.value.forEach((item) => {
      if (item.id === id) {
        item.logo = url
      }
    })
  }

  const setName = (name, id) => {
    const item = listWithCheck.getItemById(id)
    if (item) {
      item.name = name
    }
  }

  const fetch = async () => {
    const API = useAPI()
    const response = await API.fetch('/customers', { method: 'get' })
    if (response) {
      listWithCheck.setCheckList(response.data)
    }
    return response
  }

  return {
    ...listWithCheck,
    getCertifiedNumber,
    getInProgressNumber,
    getSuspendedNumber,
    searchByName,
    setList,
    setLogo,
    setName,
    fetch
  }
})
