import { computed } from 'vue'
import { useLeaveFormModalStore } from '@/store/leaveFormModal'

export const useCreateForm = (fields) => {
  const leaveFormModalStore = useLeaveFormModalStore()
  const creationFields = fields

  const initLeaveFormModal = () => {
    leaveFormModalStore.initFields(creationFields)
  }

  const setCreateField = (key, obj) => {
    leaveFormModalStore.setField(key, obj)
  }

  const getCreateFields = () => leaveFormModalStore.fields

  const emptyFieldsNumbersDefault = computed(() => {
    return Object.entries(getCreateFields()).reduce((acc, item) => {
      if (typeof item[1] === 'object' && item[1].required && !item[1].isValid) {
        acc++
      }
      return acc
    }, 0)
  })

  return {
    initLeaveFormModal,
    setCreateField,
    getCreateFields,
    handleLeaveFormModal: leaveFormModalStore.handleOpenModal,
    setChangeRouteUrl: leaveFormModalStore.setChangeRouteUrl,
    reset: leaveFormModalStore.reset,
    emptyFieldsNumbersDefault
  }
}

export default useCreateForm
