import { computed } from 'vue'
import { useStorage } from '@vueuse/core'
import moment from 'moment'
import useAPI from '@/composables/useApi'
import useUploadFile from '@/composables/useUploadFile'
import { MIMES } from '@/datas/documents'

moment.locale('fr')

export const useFile = (KEY) => {
  const API = useAPI()
  const file = useStorage('NAZ_' + KEY, {})
  const fetch = async (url) => {
    const response = await API.fetch(url, {
      method: 'get'
    })
    set(response.data)
    return response.data
  }

  const upload = async ({ file, type, domain, id }) => {
    const uf = useUploadFile()
    let fmData = new FormData()
    fmData = {
      file,
      domain,
      type,
      id
    }
    return await uf.upload(fmData)
  }

  const remove = async (url) => {
    const response = await API.fetch(url, {
      method: 'delete'
    })
    file.value = false
    localStorage.removeItem(KEY)
    return response
  }

  const approve = async () => {
    const response = await API.fetch('/file/approve/' + file.value.id, {
      method: 'get'
    })
    file.value.state = 1
    return response
  }

  const refuse = async () => {
    const response = await API.fetch('/file/refuse/' + file.value.id, {
      method: 'get'
    })
    file.value.state = 2
    return response
  }

  const changeSignMode = async (mode) => {
    const response = await API.fetch('/api/v1/file/change/mode/' + file.value.id + '/' + mode, {
      method: 'post'
    })
    file.value.signMode = mode
    return response
  }

  const set = (data) => {
    file.value = data
    localStorage.setItem('NAZ_' + KEY, data)
  }

  const reset = () => {
    set({})
    localStorage.removeItem('NAZ_' + KEY)
  }

  const getName = computed(() => {
    return file.value && file.value.originalName ? file.value.originalName + '.' + file.value.extension : null
  })

  const getFullPath = computed(() => {
    return file.value && file.value.path ? process.env.VUE_APP_MEDIAS_URL + file.value.path : null
  })

  const getCreationDate = computed(() => {
    return file.value && file.value.createdAt ? moment(file.value.createdAt).format('LL') : null
  })

  const getMimeType = computed(() => {
    return MIMES[file.value.extension] || 'unknown'
  })

  const getSignMode = computed(() => file.value.signMode)

  const isMissing = computed(() => getFullPath.value === null)

  const isWaiting = computed(() => file.value.state === 0)

  const isValidated = computed(() => file.value.state === 1)

  const isRefused = computed(() => file.value.state === 2)

  return {
    fetch,
    upload,
    remove,
    approve,
    refuse,
    changeSignMode,
    set,
    reset,
    getName,
    getFullPath,
    getCreationDate,
    getMimeType,
    getSignMode,
    isMissing,
    isWaiting,
    isValidated,
    isRefused
  }
}

export default useFile
