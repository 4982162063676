export const CUSTOMER_DOMAIN = 0
export const PROJECT_DOMAIN = 1
export const DOCUMENTS_DOMAIN = 2

export const BASE_PATHS = [
  '/customers',
  '/projects',
  '/documents'
]

export const DOMAINS_LABELS = [
  'comptes',
  'projets',
  'documents'
]

export const CUSTOMER_STORAGE = 'NAZ_CUSTOMER'
export const PROJECT_STORAGE = 'NAZ_PROJECT'

export const API_VERSION = 'v1'

export const LANGUAGES = {
  fr: 'Français',
  de: 'Allemand',
  it: 'Italien',
  sp: 'Espagnol',
  uk: 'Royaume-uni'
}

export const ALPHABET_LIST = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
