import { defineStore } from 'pinia'
import { useAPI, useListWithCheck } from '@/composables'

const STORAGE_KEY = 'NAZ_PROJECTS_LIST'

export const useProjectsStore = defineStore('useProjectsStore', () => {
  const listWithCheck = useListWithCheck(STORAGE_KEY)
  const getProjectById = listWithCheck.getItemById
  const setList = listWithCheck.setCheckList

  const API = useAPI()
  const fetch = async () => {
    const response = await API.fetch('/projects', { method: 'get' })
    if (response) {
      listWithCheck.setCheckList(response.data)
    }
    return response
  }

  const removeProject = (id) => {
    listWithCheck.removeItem(id)
  }

  const searchByName = (name) => {
    const regex = new RegExp('^' + name, 'g')
    return listWithCheck.list.value.reduce((acc, item) => {
      if (item.name.match(regex)) {
        acc.push(item)
      }
      return acc
    }, [])
  }

  return {
    ...listWithCheck,
    setList,
    getProjectById,
    fetch,
    removeProject,
    searchByName
  }
})
