import axios from 'axios'
import { ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useAuthentificationStore } from '@/store/authentification'
import { API_VERSION } from '@/datas/globals'

export const useAPI = () => {
  const authentification = useAuthentificationStore()
  const router = useRouter()
  const route = useRoute()
  const error = ref({ code: 0, message: '' })

  const handleError = (err) => {
    if (err.response && err.response.data) {
      const errData = err.response.data
      error.value = errData
      if (errData.code === 401) {
        authentification.setToken('')
        router.push({ path: '/login', query: { from: route.fullPath } })
      } else {
        console.error('error api', errData.message)
      }
    } else {
      error.value.code = -1
      error.value.message = 'no "response" key in "err" object'
      console.error(error.value.message)
    }
  }

  const fetch = async (url, { method, data, version = API_VERSION, uploadFile = false }) => {
    let headers = {}
    if (uploadFile) {
      headers = {
        'Content-Type': 'multipart/form-data'
      }
    }
    if (authentification.isLogged) {
      headers.Authorization = `Bearer ${authentification.token}`
    }
    try {
      let promise
      const fullUrl = process.env.VUE_APP_API_URL + (url.includes('/api') ? '' : '/api' + (version !== '' ? '/' + version : '')) + url
      if (method === 'post') {
        promise = axios.post(fullUrl, data, { headers })
      } else if (method === 'get') {
        promise = axios.get(fullUrl, { headers })
      } else {
        promise = axios({
          method,
          url: fullUrl,
          data,
          headers
        })
      }
      const response = await promise
      if (response.data.status >= 400) {
        return await throwError(response)
      }
      return response
    } catch (e) {
      handleError(e)
    }
    return { data: false }
  }

  const throwError = async (response) => {
    throw new Error(response)
  }

  return { fetch, handleError, error }
}

export default useAPI
